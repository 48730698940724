<template>
  <div class="auth-page with-bg">
    <public-header :button="{url: '/register', text: $t('auth.sign_up')}"/>
    <div class="auth-page__content">
      <div class="container">
        <h2 class="auth-page__title">{{$t('auth.signin_title')}}</h2>
        <div class="box mg-t-30 mg-b-30">
          <div class="title-bold center-text mg-b-20">{{$t('auth.sign_in')}}</div>
          <div class="flex justify-center mg-t-15">
            <form class="w-400" @submit.prevent="onLogin">
              <div class="form-element with-symbol mg-b-15">
                <div class="form-symbol">
                  <i class="fas fa-envelope"/>
                </div>
                <input type="email" autocomplete="email" :placeholder="$t('auth.email')" required @input="onInput('email', $event.target.value)" class="form-control"/>
              </div>
              <div class="form-element with-icon with-symbol mg-b-15">
                <div class="form-symbol">
                  <i class="fas fa-lock"/>
                </div>
                <input :type="password ? 'password' : 'text'" autocomplete="current-password" required @input="onInput('password', $event.target.value)" :placeholder="$t('auth.password')" class="form-control"/>
                <img src="/images/icons/eye.png" @click="togglePass" class="form-icon"/>
              </div>
              <div class="mg-t-20 mg-b-30">
                <checkbox id="keepme">{{$t('auth.remember')}}</checkbox>
              </div>
              <div class="flex justify-center mg-b-30">
                <button type="submit" class="btn btn--red w-175">{{$t('auth.sign_in')}}</button>
              </div>
              <div class="auth-page__link" @click="forgotToggle">{{$t('auth.forgot')}}</div>
            </form>
          </div>
          <div v-if="forgot" class="forgot-pass mg-t-20 pd-t-20">
            <div class="flex justify-center mg-t-15">
              <form @submit.prevent="sendResetPass" class="w-400">
                <div class="form-element with-symbol mg-b-15">
                  <div class="form-symbol">
                    <i class="fas fa-envelope"/>
                  </div>
                  <input v-model="resetEmail" type="email" autocomplete="email" required :placeholder="$t('auth.email')" class="form-control"/>
                </div>
                <div class="flex justify-center mg-t-20">
                  <button type="submit" class="btn btn--red w-175">{{$t('global.submit')}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <forgot-password ref="forgotModal"/>
    <reset-password :token="resetToken" ref="resetModal"/>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Checkbox from '@/components/checkbox/checkbox'
import Loading from '@/components/loading/Loading'
import { Auth, Store, User } from '@/api/api'
import { mapGetters } from 'vuex'
import ForgotPassword from '@/components/forgotPassword/forgotPassword'
import ResetPassword from '@/components/resetPassword/ResetPassword'
import publicHeader from '@/components/publicHeader/publicHeader'
export default {
  name: 'Login',
  components: { ResetPassword, ForgotPassword, Loading, Checkbox, publicHeader },
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      resetEmail: '',
      resetToken: null,
      verifyToken: null,
      isLoading: false,
      password: true,
      forgot: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      isAdmin: 'auth/isAdmin',
      isOperator: 'auth/isOperator',
      isVerified: 'auth/isVerified',
      isVendor: 'auth/isVendor'
    })
  },
  updated () {
    if (this.isAuthed) {
      if (this.isAdmin) {
        this.redirect('/dashboard/admin/products')
      } else if (this.isOperator) {
        this.redirect('/dashboard/admin/products')
      } else if (this.isVerified) {
        this.redirect('/dashboard/orders')
      } else {
        this.redirect('/dashboard/profile/me/edit')
      }
    }
  },
  mounted () {
    this.resetToken = this.$route.query.resetToken
    this.verifyToken = this.$route.query.verifyToken
    this.verifyEmail = this.$route.query.verifyEmail

    if (this.resetToken) {
      this.$refs.resetModal.setState(1)
      this.$refs.resetModal.toggle()
    } else if (this.verifyToken && this.verifyEmail) {
      this.verifyAccount()
    }
  },
  methods: {
    redirect (url) {
      window.location = url
    },
    async verifyAccount () {
      this.isLoading = true

      try {
        await Auth.verify(this.verifyEmail, this.verifyToken)

        this.$toast.success(this.$t('auth.login_now'))
      } catch (err) {
        this.$toast.error(this.$t('auth.fail_verify'))
      } finally {
        this.isLoading = false
      }
    },
    onInput (key, value) {
      this.form = {
        ...this.form,
        [key]: value
      }
    },
    async onLogin () {
      this.isLoading = true

      try {
        const { data } = await Auth.login(this.form.email, this.form.password)

        Auth.setToken(data.token)

        const user = await User.me()

        const store = await Store.getMine()

        this.$store.dispatch('auth/login', {
          token: data.token,
          user: {
            ...user.data,
            store: store.data
          }
        })
      } catch (e) {
        if (e.response.status === 422) {
          this.$toast.error(this.$t('auth.verify_email'))
        } else if (e.response.status === 401) {
          this.$toast.error(this.$t('auth.invalid_credentials'))
        } else {
          this.$toast.error(this.$t('auth.login_fail'))
        }
      } finally {
        this.isLoading = false
      }
    },
    async sendResetPass () {
      this.isLoading = true

      try {
        await Auth.sendReset(this.resetEmail)

        this.resetEmail = ''
        this.forgot = false
        this.$refs.forgotModal.setStatus(1)
        this.$refs.forgotModal.toggle()
      } catch (err) {
        this.$refs.forgotModal.setStatus(2)
        this.$refs.forgotModal.toggle()
      } finally {
        this.isLoading = false
      }
    },
    togglePass () {
      this.password = !this.password
    },
    forgotToggle () {
      this.forgot = !this.forgot
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
