<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <div class="modal-close" @click="toggle">
      <i class="fas fa-times"/>
    </div>
    <div class="w-400 flex direction-column align-center pd-20" v-if="state === 1">
      <img src="/images/modal1.png"/>
      <div class="title red pd-t-20 pd-b-20">{{$t('auth.forgot')}}</div>
      <p class="paragraph">{{$t('auth.reset_pass_txt')}}</p>
    </div>
    <div class="w-400 flex direction-column align-center pd-20" v-if="state === 2">
      <img src="/images/modal1.png"/>
      <div class="title red pd-t-20 pd-b-20">{{$t('auth.forgot')}}</div>
      <p class="paragraph">{{$t('auth.email_not_found')}}</p>
      <p class="paragraph">{{$t('auth.difficulty_login')}}</p>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: 'ForgotPassword',
  components: { },
  props: [
    'status'
  ],
  data () {
    return {
      showModal: false,
      state: 2
    }
  },
  created () {
  },
  methods: {
    toggle () {
      this.showModal = !this.showModal
    },
    setStatus (status) {
      this.state = status
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 25px;
  font-weight: 500;
}

.paragraph {
  text-align: center;
  color: #444;
  font-weight: 300;
}

a {
  color: #00A4D9;
}
</style>
