<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
  >
    <div class="modal-close" @click="toggle">
      <i class="fas fa-times"/>
    </div>
    <div class="w-400 flex direction-column align-center pd-20">
      <img :src="state === 1 ? '/images/modal1.png' : '/images/modal2.png'"/>
      <div class="title red pd-t-20 pd-b-5">{{$t('auth.reset_txt1')}}</div>
      <p class="paragraph" v-if="state === 1">{{$t('auth.reset_txt2')}}</p>
      <p class="paragraph" v-if="state === 2">{{$t('auth.reset_txt3')}}</p>
      <form class="pd-t-20" v-if="state === 1" @submit.prevent="resetPassword">
        <div class="form-element with-symbol mg-b-15">
          <div class="form-symbol">
            <i class="fas fa-envelope"/>
          </div>
          <input type="email" autocomplete="email" required v-model="form.email" :placeholder="$t('auth.email')" class="form-control"/>
        </div>
        <div class="form-element with-icon with-symbol mg-b-15">
          <div class="form-symbol">
            <i class="fas fa-lock"/>
          </div>
          <input :type="password ? 'password' : 'text'" required v-model="form.password" :placeholder="$t('auth.new_pass')" class="form-control"/>
          <img src="/images/icons/eye.png" @click="togglePass" class="form-icon"/>
        </div>
        <div class="form-element with-icon with-symbol mg-b-15">
          <div class="form-symbol">
            <i class="fas fa-lock"/>
          </div>
          <input :type="password ? 'password' : 'text'" autocomplete="new-password" required v-model="form.password2" :placeholder="$t('auth.confirm_new_pass')" class="form-control"/>
          <img src="/images/icons/eye.png" @click="togglePass" class="form-icon"/>
        </div>
        <div class="flex justify-center mg-t-20">
          <button type="submit" class="btn btn--red">{{$t('auth.reset')}}</button>
        </div>
      </form>
      <div class="flex justify-center mg-t-20" v-else>
        <button class="btn btn--red" @click="toggle">{{$t('auth.sign_in')}}</button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { Auth } from '@/api/api'

export default {
  name: 'ResetPassword',
  components: { },
  props: [
    'token'
  ],
  data () {
    return {
      form: {
        email: '',
        password: '',
        password2: ''
      },
      showModal: false,
      password: true,
      state: 1
    }
  },
  created () {
  },
  methods: {
    toggle () {
      this.showModal = !this.showModal
    },
    setState (state) {
      this.state = state
    },
    togglePass () {
      this.password = !this.password
    },
    async resetPassword () {
      if (this.form.password !== this.form.password2) {
        this.$toast.error(this.$t('auth.pass_no_match'))

        return
      }

      this.isLoading = true

      try {
        await Auth.passReset(this.form.email, this.form.password, this.token)

        this.setState(2)
      } catch (e) {
        this.$toast.error(this.$t('auth.reset_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 25px;
  font-weight: 500;
}

.paragraph {
  text-align: center;
  color: #444;
  font-weight: 300;
  margin: 0;
}

a {
  color: #00A4D9;
}
</style>
